.Home {
  text-align: center;
}

canvas {
  width: 100% !important;
  height: 90vh !important;
  position: absolute;
  right: 0;
}

.heading {
  margin: 0;
  color: var(--black-primary);
  font-weight: 900;
  user-select: none;
}

.h1 {
  font-size: 52px;
  padding-top: 145px;
}

.h2 {
  font-size: 40px;
  padding-bottom: 10px;
}

.p {
  padding: 20px 0;
  font-size: 17px;

  line-height: 1.5;
  width: 50%;
  margin-left: auto;
  margin-right: auto;
}

.headingSpan {
  color: var(--blue-primary);
}

.gap {
  margin-top: 100px;
  margin-bottom: 130px;
}

.bannerContainer {
  background-image: url("../../../public/viber_image_2023-10-27_12-20-02-611.png");
  background-size: cover;
  width: 100%;
  height: 90vh;
  z-index: -200;
  background-position: bottom;
}

.modernBannerContainer {
  background: #dbe5e7;
  background-size: cover;
  height: calc(100vh - 60px);
  z-index: -200;
  background-position: bottom;
  width: 100%;
  display: flex;
  justify-content: space-between;
  position: relative;
  overflow: hidden;
}

.left-side {
  width: 50%;
  height: 100%;
  overflow: hidden;
}

.modernBannerTitle {
  text-align: center;
  height: 50%;
  font-weight: 700;
  font-size: 35px;
  word-wrap: break-word;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 40px;
}

.strokeme {
  text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000,
    1px 1px 0 #000;
}

.strokeme-blue {
  text-shadow: -1px -1px 0 var(--blue-primary), 1px -1px 0 var(--blue-primary),
    -1px 1px 0 var(--blue-primary), 1px 1px 0 var(--blue-primary);
}

.modernBannerPic {
  display: flex;
  justify-content: center;
  z-index: 100;
  background-color: #dbe5e7;
  width: 70%;
}

.modernBannerImg {

  height: 100%;
  width: 100%;
}

.modernBannerParticles {
  /* height: 49%; */
}

.mb-fade {
  height: 150px;
  width: 100%;
  margin-top: -120px;
  background: rgb(255, 255, 255);
  background: linear-gradient(
    0deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 1) 50%,
    transparent 100%
  );
}

.mb-fade-top {
  z-index: 200;
  margin-bottom: -120px;
  background: transparent;
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 1) 20%,
    transparent 100%
  );
  height: 80px;
  width: 100%;
  position: absolute;
}

@media only screen and (max-width: 1050px) {
  .modernBannerParticles {
    /* display: none; */
  }
  .modernBannerTitle {
    height: 50%;
    font-weight: 700;
    font-size: 25px;
    padding-left: 40px;
  }
  .mb-fade-top {
    /* display: none; */
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 1) 0%,
      rgba(255, 255, 255, 1) 10%,
      transparent 100%
    );
    height: 40px;
  }

  .mb-fade {
    margin-top: -80px;
    background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 1) 0%,
      rgba(255, 255, 255, 1) 60%,
      transparent 100%
    );
  }
}

@media only screen and (max-width: 585px) {
  .heading {
    font-size: 30px;
  }
  .headingSpan {
    font-size: 35px;
  }
  .modernBannerContainer {
    /* background: #dbe5e7; */
    height: calc(100vh - 150px);
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  .left-side {
    width: 100%;
    height: fit-content;
    overflow: hidden;
    top: 50px;
  }

  .modernBannerTitle {
    font-weight: 800;
    padding-top: 30px;
    font-size: 18px;
    padding-left: 40px;
    height: auto;
    word-wrap: break-word;
    display: flex;

    flex-direction: column;
    justify-content: center;
  }

  .modernBannerPic {
    height: 400px;
    justify-content: end;
    width: 100%;

    /* flex-direction: row; */
    /* height: fit-content; */
  }
  
  .modernBannerImg {

    width: 90%;
    height: 80%;

    padding-top: 30px;
  }
}

.particles-modern {
  position: absolute;
  top: 0;
  width: 100% !important;
  height: 100% !important;
}

.personImage {
  z-index: 1000;
  position: absolute;
  bottom: 15px;
  left: 50%;
  right: 50%;
  transform: translate(-50%, -10%);
  width: 200px;
  background-position: center;
}

img {
  max-width: 100%;
  user-select: none;
}

.personImage img {
  width: 90px;
}

.line {
  width: 0%;
  border: 1px solid gray;
  position: relative;
  max-width: 90%;
  transition: 1s;
  opacity: 0;
}

.line::after {
  content: "";
  display: inline-block;
  width: 7px;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  transform: translate(100%, -50%);
  height: 7px;
  border-radius: 7.5px;
  background-color: #2f86c1;
}

.lineRightContainer {
  position: absolute;
  right: 0;
}

.lineRight {
  width: 0%;
  border: 1px solid gray;
  max-width: 90%;
  transition: 1.5s;
  position: absolute;
  right: 3px;
  opacity: 0;
}

.lineRight::after {
  content: "";
  display: inline-block;
  width: 7px;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  transform: translate(-50%, -50%);
  height: 7px;
  border-radius: 7.5px;
  background-color: #2f86c1;
}

.linePositionRelative {
  position: relative;
}

.circleContainer {
  position: absolute;
  top: -15px;
  max-width: 20%;
}

.circle1Container {
  left: 15%;
}

.circle2Container {
  left: 45%;
}

.circle3Container {
  left: 75%;
}

.circle {
  display: inline-block;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #2f86c1;
}

.circle-empty {
  display: inline-block;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #ffffff;
  border: 1px solid black;
}

.mission {
}

.absoluteFix {
  height: 517px;
}

.h2mission {
  margin: 30px 0;
}

.h3 {
  font-size: 30px;
}

.smallp {
  font-size: 15px;
  line-height: 1.5;
  margin-top: 16px;
}

.collapse {
  text-align: left;
  flex-basis: 70%;
}

.collapseContainer {
  width: 85%;
  margin: 0 auto;
}

.collapseContainerinner {
  margin: 0 100px;
  display: flex;
  border-bottom: 1px solid #3c3c3c;
  padding: 20px;
}

.emptyCircleContainer {
  flex-basis: 15%;
}

.emptyCircle {
  display: inline-block;
  width: 20px;
  height: 20px;
  border: 1px solid black;
  border-radius: 50%;
  margin-top: 37px;
}

.projectsContainer {
}

.projectsContainerInner {
  width: 80%;
  margin: 0 auto;
  display: flex;
  padding: 40px 0;
  flex-wrap: wrap;
  overflow: hidden;
}

.projectsContainerInner img {
  object-fit: cover;
  object-position: bottom;
  width: 400px;
  height: 350px;
  background-color: #dbe5e7;
}

.singleProject {
  flex-basis: 33.333%;
  display: flex;
  justify-content: center;
  margin-bottom: 35px;
  flex-grow: 1;
}

.singleProjectCircle {
  width: 300px;
  height: 300px;
  border-radius: 50%;
  border: 1px solid black;
  overflow: hidden;
}
/* 
.singleProjectCircle:hover {
    background: #2F86C1;
    color: white;
} */

.project-img {
  display: flex;
}

.project-img:hover {
  display: none;
  opacity: 0;
}

.employeeContainer {
}

.employeeContainerInner {
  width: 80%;
  margin: 0 auto;
  display: flex;
  padding: 40px 0;
  flex-wrap: wrap;
}

.singleEmployee {
  flex-basis: 20%;
  display: flex;
  justify-content: center;
  flex-grow: 1;
  margin-bottom: 10px;
}

.singleEmployeeCircle {
  width: 215px;
  height: 215px;
  border-radius: 50%;
  border: 1px solid black;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.singleEmployeeCircle:hover {
  background: #2f86c1;
  color: white;
}

.singleEmployeeCircle div:first-child,
.singleEmployeeCircle div:nth-child(2) {
  margin-bottom: 8px;
}

.singleEmployeeCircle div:nth-child(3) {
  font-weight: bold;
}

.getInTouchParagraph {
  margin: 35px 0;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  text-align: left;
}

.contactContainer {
}

.contactContainerInner {
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 20px;
  gap: 5px;
}

.form,
.info {
  flex-basis: 48%;
  overflow: hidden;
  padding-top: 15px;
}

.checkBox {
  display: flex;
  margin-bottom: 10px;
}

.buttonForm {
  display: flex;
}

.singleContact {
  display: flex;
  gap: 10px;
  text-align: left;
  margin-bottom: 10px;
}

.infoName {
  font-weight: bold;
  margin-bottom: 5px;
}

.infoDetails {
  color: #2f86c1;
}

.iframe {
  border-radius: 20px;
  border: 1px solid transparent;
  overflow: hidden;
}

.footer {
  padding-top: 30px;
  border-top: 1px solid gray;
  width: 99%;
}

.footerInner {
  width: 80%;
  margin: 0 auto;
}

.footerLogo {
  text-align: left;
}

.footerNavigation {
  margin-top: 15px;
  margin-bottom: 15px;
  display: flex;
  justify-content: space-between;
}

.footerLinks a {
  text-decoration: none;
  font-size: 15px;
  text-align: left;
  display: block;
}

.footerLinkList a {
  margin-bottom: 5px;
}

.footerLinkTitle {
  color: #2f86c1;
  font-weight: bold;
  font-size: 18px;
  padding-bottom: 10px;
}

.footerLinkList a {
  color: black;
}

.footerCopyright {
  width: 80%;
  border-top: 1px solid black;
  margin: 0 auto;
  padding: 15px;
}

.mobileNavbar {
  position: fixed;
  z-index: 1000;
  top: 0;
  bottom: 0;
  right: 0;
  background: white;
  width: 300px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
}

.linksContainer a {
  text-decoration: none;
  color: #2f86c1;
  font-size: 20px;
  margin-bottom: 10px;
  text-transform: capitalize;
}

.linksContainerMob a {
  text-decoration: none;
  color: #2f86c1;
  font-size: 20px;
  margin-bottom: 10px;
  text-transform: capitalize;
}

.linkContainerMobile {
  display: flex;
  justify-content: center;
  z-index: 10000;
}

@media only screen and (max-width: 585px) {
  .heading {
    font-size: 40px;
  }

  .p {
    width: 73%;
  }

  .footer {
    padding-top: 0;
    border-top: none;
  }
}

@media only screen and (max-width: 964px) {
  .absoluteFix {
    height: auto;
  }

  .absoluteFix div {
    position: static;
    border: 0;
  }

  .circleContainer {
    display: block;
    max-width: 73%;
    margin: 0 auto;
  }

  .line,
  .lineRight {
    max-width: 100% !important;
  }

  /* .circle {
        display: none;
    } */

  .line::after {
  }

  .footerLogo {
    margin-top: 20px;
  }
}

@media only screen and (max-width: 768px) {
  .collapseContainerinner {
    width: 73%;
    display: block;
    margin: auto;
  }

  .contactContainerInner {
    flex-direction: column;
  }

  .emptyCircleContainer {
    display: none;
  }

  .line::after {
    display: none;
  }

  .footerNavigation {
    display: block;
  }

  .footerLinks {
    margin-bottom: 15px;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}
