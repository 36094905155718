.container-s {
    padding: 60px 0;
    background: #DBE5E7;
    background: linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(219,229,231,1) 50%, rgba(255,255,255,1) 100%);
    background: linear-gradient(#ffffff, #DBE5E7);
}

.text-s {
    text-align: center;
    width: 60%;
    margin: auto;
}

@media only screen and (max-width: 1050px) {
    .container-summary {
        border-top: 1px solid lightgray;
        padding-top: 50px;
    }
}