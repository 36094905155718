.line {
  width: 0%;
  border: 1px solid gray;
  position: relative;
  max-width: 90%;
  transition: 1s;
  opacity: 0;
  border-right: none;
  border-left: none;
  border-bottom: none;
}

.line::after {
  content: "";
  display: inline-block;
  width: 7px;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  transform: translate(100%, -50%);
  height: 7px;
  border-radius: 7.5px;
  background-color: #2f86c1;
}

.twoline {
  width: 0%;
  border: 1px solid gray;
  position: relative;
  max-width: 90%;
  transition: 1s;
  opacity: 0;
  border-right: none;
  border-left: none;
  border-bottom: none;
  padding-bottom: 80px;
}

.container-asdasd {
  height: fit-content;
  position: relative;
  margin-bottom: 30px;
}

.container-twoline {
  height: fit-content;
  position: relative;
  display: flex;
}

.container-steps-three {
  margin-top: -20px;
  display: flex;
  justify-content: center;
  width: 100%;
  gap: 113px;
  margin-left: 80px;
}

.container-steps {
  display: flex;
  justify-content: center;
  width: 100%;
  gap: 150px;
  margin-left: 80px;
}

.item-s {
  width: 20%;
  margin-top: -15px;
}

.item-s-b {
  width: 20%;
  margin-top: -15px;
}

.full {
  display: inline-block;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #2f86c1;
}

.empty {
  display: inline-block;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #ffffff;
  border: 1px solid black;
}

.title-steps {
  font-size: 25px;
  padding: 10px;
  font-weight: 700;
}

.side-piece {
  border: none;
  border-top-right-radius: 80px 80px;
  border-bottom-right-radius: 80px 80px;
  width: 0;
  opacity: 0;
  transition: 1s;
}

.bottom-steps {
  margin-top: -1px;
  height: fit-content;
  width: 90%;

  position: relative;
  height: 300px;
}

.bottom-steps-line {
  border: 1px solid gray;
  float: right;
  position: relative;
  border-right: none;
  border-left: none;
  border-bottom: none;
  width: 20%;
  opacity: 0;
  transition: 1s;
}

.bottom-steps-line::after {
  content: "";
  display: inline-block;
  width: 7px;
  position: absolute;
  left: -10px;
  top: -1.3px;
  bottom: 0;
  transform: translate(100%, -50%);
  height: 7px;
  border-radius: 7.5px;
  background-color: #2f86c1;
} 

.container-steps-bottom {
  display: flex;
  justify-content: center;
  gap: 150px;
  width: 100%;
  margin-left: 10px;


}

.smallscreen-title-steps {
  display: none;
}

@media only screen and (max-width: 850px) {
  .container-steps-three {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    width: fit-content;
  }

  .container-steps {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: auto;
    gap: 40px;
  }
  .full {
    display: none;
  }
  .item-s {
    width: 70%;
  }
  .empty {
    display: none;
  }
  .side-piece {
    display: none;
  }
  .twoline {
    border-top: none;
  }
  .fullscreen-title-steps {
    display: none;
  }
  .smallscreen-title-steps {
    display: flex;
    justify-content: center;
    padding: 0;
  }

  .bottom-steps {
    align-items: start;
    height: auto;
    opacity: 1;
    margin-bottom: 50px;
  }

  .item-s-b {
    width: 50%;
    margin-left: 80px;
    text-align: center;
  }

  .container-steps-bottom {
    display: flex;
    flex-direction: column;
    gap: 50px;
    justify-content: center;
    width: auto;
    text-align: center;
    padding-bottom: 100px;
    position: relative;
  }
  .bottom-steps-line {
    border-top: none;
    text-align: center;
    float: left;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .bottom-steps-line::after {
    display: none;
  }
}
