.container-pf {
    display: grid;
    width: 80%;
    margin: auto;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 100;
}

.item-pf {
    text-align: start;
    margin: 20px;
}

.heading-pf {
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 18px;
    font-weight: 700;
}

.headingspan {
    color: var(--blue-primary);
}

.circle-pf {
    display: inline-block;
    width: 20px;
    height: 20px;
    border: 1px solid black;
    border-radius: 50%;
}

.paragraph-pf {
    margin-top: 15px;
    margin-left: 30px;
} 

@media only screen and (max-width: 1050px) {
    .container-pf {
        grid-template-columns: 1fr;
    }
}