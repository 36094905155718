.lineRight {
    width: 0%;
    border: 1px solid gray;
    max-width: 90%;
    transition: 1.5s;
    position: absolute;
    right: 3px;
    opacity: 0;
}

.container {
    height: 150px;
    border: 2px solid red;
    padding: 5px;
    position: absolute;
    width: 90%;
}

.bubble {
    border: 2px solid grey;
    position: absolute;
    right: 0;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    transition: transform 0.5s ease;
}

.bubble:active {
    transform: translateX(-100px); /* Move 100px to the right */
  }

.bubble::before {
    content: '';
    position: absolute;
    transition: left 1s ease;
    animation: anime 3s infinite;
}

@keyframes anime {
    0% {
      right: -100%;
    }
    100% {
      right: 100%;
    }
  }
