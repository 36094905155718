html {
  scroll-behavior: smooth;
 
}

body {
  margin: 0;
  font-family: Montserrat;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  --blue-primary: rgb(47, 134, 193);
  --black-primary: #3C3C3C;
  scroll-behavior: smooth;
  padding-top: 93px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#canvas {
  position: absolute;
  top: 0;
  left: 0;
}