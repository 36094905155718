.navbarContainer {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: white;
  z-index: 100000;
  // box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .2);
  
  .logoContainer {
    width: 90px;
  }

  .navbarContainerInner {
    padding: 20px 60px;
    display: flex;
    justify-content: space-between;

    .hamburger {
      color: var(--blue-primary);
      cursor: pointer;
      display: none;
    }

    .hamburger-close {
      padding: 30px;
      top: 10px;
      right: 10px;
      z-index: 100000;
      cursor: pointer;
    }

    .linksContainerMob {
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      align-items: center;
      gap: 20px;
    }

    .linksContainer {
      display: flex;
      justify-content: space-between;
      align-items: center;
      
      .linkContainer {
        &:not(:last-child) {
          margin-right: 50px;
        }
      }
    }

    .tab {
      font-weight: 900;
      cursor: pointer;
      position: relative;
      transition: color 0.3s;
      color: var(--blue-primary);
    }

    .tab.active {
      color: black;
    }

    .tab::after {
      content: '';
      position: absolute;
      bottom: -5px;
      left: 50%;
      width: 0;
      height: 2px;
      background-color: #000000;
      transition: width 0.3s, left 0.3s;
      transform: translateX(-50%);

    }

    .tab.active::after {
      width: 50%;
    }

    .tab:not(.active)::after {
      width: 0;
      left: 50%;
    }

  }
}

@media (max-width: 1000px) {
  .hamburger {
    display: block !important;
    align-self: center;
  }

  .navbarContainerInner {
    padding: 20px 30px !important;
  }

  .linkContainer {
    display: none !important;
  }
}